import React, { useEffect, useState } from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Input from "../components/Input";
import { StaticImage } from "gatsby-plugin-image";

const IndexPage = () => {
  const [price, setPrice] = useState<number | null>(null);
  const [budget, setBudget] = useState<number | null>(null);

  const canBuy = budget === null || price === null || price === 0 ? null : Math.floor(budget / price);
  const recycle = canBuy === null ? null : canBuy * 0.1;
  const buyAgain = recycle === null || price === null ? null : Math.floor(recycle / price);

  return (
    <Layout>
      <Seo title="Home" />
      <div className="w-full h-full text-white py-20">
        <div className="flex flex-1 justify-center font-bold text-4xl items-end space-x-2">
          <span className="z-10">Longero kalkulaator</span>
        </div>
        <div className="flex flex-1 items-center mt-20 bg-gray-50 text-purple-500 rounded-lg p-4 justify-center">
          <div>
            <div className="font-semibold text-center text-xl mb-2">Kui palju maksab longero su kodupoes?</div>
            <div className="flex flex-1 justify-center items-center">
              <Input
                type="number"
                min={0}
                onChange={({ target }) => setPrice(target.value ? Number(target.value) : null)}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-1 items-center mt-10 bg-gray-50 text-purple-500 rounded-lg p-4 justify-center">
          <div>
            <div className="font-semibold text-center text-xl mb-2">Kui palju sul raha on?</div>
            <div className="flex flex-1 justify-center items-center">
              <Input
                type="number"
                min={0}
                onChange={({ target }) => setBudget(target.value ? Number(target.value) : null)}
              />
              {budget === 0 && <span className="ml-2 font-bold">rott</span>}
            </div>
          </div>
        </div>
        {canBuy !== null && (
          <div className="flex flex-1 items-center mt-10 bg-gray-50 text-purple-500 rounded-lg p-4 justify-center">
            <div>
              {canBuy >= 1 && (
                <>
                  <div className="font-semibold text-center text-xl mb-2">
                    Sa saad endale lubada{" "}
                    <span className="font-bold underline text-purple-700 text-3xl">{canBuy} longerot!</span>
                  </div>
                  <div className="flex flex-1 space-x-1 justify-center w-full flex-wrap items-center">
                    {Array.from(Array(canBuy > 200 ? 200 : canBuy)).map(() => (
                      <StaticImage alt="strongero" src="../images/strongero.png" className="w-5" />
                    ))}
                    {canBuy > 200 && <span className="ml-2 font-semibold">jne...</span>}
                  </div>
                </>
              )}
              {canBuy < 1 && (
                <div className="font-semibold text-center text-xl">
                  Sry, sa jääd vee peale täna. Sa ei saa ühtegi longerot lubada endale.
                  <br />
                  <span className="text-lg font-normal">
                    Näe, vaata{" "}
                    <a className="underline" href="https://www.facebook.com/tasutakraanivesi/">
                      siia
                    </a>
                    !
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
        {recycle !== null && Math.floor(recycle) >= 1 && (
          <div className="flex flex-1 items-center mt-10 bg-gray-50 text-purple-500 rounded-lg p-4 justify-center">
            <div>
              <div className="font-semibold text-center text-xl">
                <div>
                  Kui taara ära viid siis saad{" "}
                  <span className="underline font-bold text-purple-700">{Number(recycle).toFixed(2)} euri</span> tagasi.
                </div>
                <div className="mt-4">Tead mis selle eest saab v?</div>
                <div className="text-center mt-2">Just täpselt.</div>
                <div className="text-center mt-2 underline font-bold text-purple-700">{buyAgain} longerot</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default IndexPage;
